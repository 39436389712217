.bg-header {
  background-color: #221b1d;
}

.bg-gray {
  background-color: #c8c8c8;
}

.bg-cream {
  background-color: #f1ede1;
}

.bg-cream-light {
  background-color: #faf7ed;
}

.bg-yellow {
  background-color: #ffa800;
}

.bg-dark-green {
  background-color: #69816b;
}

.bg-green {
  background-color: #5c9673;
}

.br-0-5 {
  border-radius: 0.5rem;
}

.br-1-25 {
  border-radius: 1.25rem;
}

.br-t-1-25 {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

@media (min-width: 768px) {
  .md\:br-1-25 {
    border-radius: 1.25rem;
  }
}

/* @media (min-width: 0px) and (max-width: 640px) {
  .ssm\:hidden {
    display: none;
  }
} */
