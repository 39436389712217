@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #211b1d;
}

@media (min-width: 75rem) {
  .container {
    max-width: 81.25rem;
  }
}

.main-body {
  min-height: calc(100vh);
}

@media (min-width: 48rem) {
  .main-body {
    min-height: calc(100vh);
  }
}

.main-button {
  padding: 20px 52px 20px 52px;
  background-color: #69816b;
  color: #fff;
  font-weight: 900;
  font-size: 1.5rem;
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

.report-btn {
  padding: 1.25rem 3.25rem 1.25rem 3.25rem;
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

@media (min-width: 768px) {
  .report-btn {
    padding: 2rem 4.25rem 2rem 4.25rem;
  }
  .main-button {
    padding: 1.9rem 4rem 1.9rem 4rem;
  }
}

.modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  background-color: rgba(255, 255, 255, 0.3);
}

#iIcon {
  cursor: pointer;
  display: inline;
  padding: 0.3rem;
  width: 1.7rem;
}
