.f-size-0-875 {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.f-size-1 {
  font-size: 1rem;
  line-height: 1rem;
}

.f-size-1-125 {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

.f-size-1-25 {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.f-size-1-35 {
  font-size: 1.35rem;
  line-height: 1.35rem;
}

.f-size-1-5 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.f-size-2 {
  font-size: 2rem;
  line-height: 2rem;
}

.f-size-2-5 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.f-size-3-125 {
  font-size: 3.125rem;
  line-height: 3.125rem;
}

.f-size-4 {
  font-size: 4rem;
  line-height: 4rem;
}

.lh-1 {
  line-height: 1rem;
}

.lh-1-2 {
  line-height: 1.2rem;
}

.lh-1-5 {
  line-height: 1.4rem;
}

.color-green {
  color: #69816b;
}

.color-gray {
  color: #7d7d7d;
}

.color-white {
  color: #fff;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 900;
}

.fw-900 {
  font-weight: 900;
}

.lt-m-0-01 {
  letter-spacing: -0.01rem;
}

.lt-0-01 {
  letter-spacing: 0.01rem;
}

.lt-0-02 {
  letter-spacing: 0.02rem;
}

.lt-0-03 {
  letter-spacing: 0.03rem;
}

.underline {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .md\:f-size-1-125 {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .md\:f-size-1-25 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  .md\:f-size-1-5 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  .md\:f-size-1-9 {
    font-size: 1.9rem;
    line-height: 1.9rem;
  }

  .md\:f-size-2-5 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .md\:f-size-3 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .md\:f-size-3-125 {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  .md\:f-size-4 {
    font-size: 4rem;
    line-height: 4rem;
  }

  .md\:lt-0-01 {
    letter-spacing: 0.01rem;
  }

  .md\:lt-0-02 {
    letter-spacing: 0.02rem;
  }

  .md\:lt-0-03 {
    letter-spacing: 0.03rem;
  }
  .md\:lt-0-04 {
    letter-spacing: 0.04rem;
  }
  .md\:lt-0-08 {
    letter-spacing: 0.08rem;
  }
  .md\:lh-1-35 {
    line-height: 1.35rem;
  }
  .md\:lh-1-4 {
    line-height: 1.4rem;
  }
  .md\:lh-1-5 {
    line-height: 1.5rem;
  }
  .md\:lh-1-6 {
    line-height: 1.6rem;
  }
  .md\:lh-1-7 {
    line-height: 1.7rem;
  }
  .md\:lh-1-8 {
    line-height: 1.8rem;
  }
}
