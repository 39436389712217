.h-3-5 {
  height: 3.5rem;
}

.w-1-125 {
  width: 1.125rem;
}

.w-2-2 {
  width: 2.2rem;
}

.mw-2-625 {
  max-width: 2.625rem;
}

.w-16-25 {
  width: 16.25rem;
}

.w-100p {
  width: 100%;
}

.h-100p {
  height: 100%;
}

.wh-0-68 {
  width: 0.68rem;
  height: 0.68rem;
}

.wh-0-68 {
  width: 0.68rem;
  height: 0.68rem;
}

.mw-21-25 {
  max-width: 21.25rem;
}
.mw-16-25 {
  max-width: 16.25rem;
}

.mt-2-7 {
  margin-top: 2.7rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-6 {
  margin-top: 6rem;
}

.mt-7 {
  margin-top: 7rem;
}

.mt-8 {
  margin-top: 8rem;
}

.mb-0-5 {
  margin-bottom: 0.5rem;
}

.mb-0-75 {
  margin-bottom: 0.75rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-1-125 {
  margin-bottom: 1.125rem;
}

.mb-1-25 {
  margin-bottom: 1.25rem;
}

.mb-1-5 {
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-2-5 {
  margin-bottom: 2.5rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-3-5 {
  margin-bottom: 3.5rem;
}

.mb-3-75 {
  margin-bottom: 3.75rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mb-5 {
  margin-bottom: 5rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.mb-7-5 {
  margin-bottom: 7.5rem;
}

.p-0-5 {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.p-2-875 {
  padding: 2.875rem;
}
.pt-2-5 {
  padding-top: 2.5rem;
}
.pb-3 {
  padding-bottom: 3rem;
}

.pl-1-25 {
  padding-left: 1.9rem;
  padding-right: 1.9rem;
}

.gap-2 {
  gap: 2rem;
}

.cg-2 {
  column-gap: 2rem;
}
.rg-3 {
  row-gap: 3rem;
}

@media (min-width: 768px) {
  .md\:mr-1 {
    margin-right: 1rem;
  }

  .md\:mt-1 {
    margin-top: 1rem;
  }

  .md\:mt-2 {
    margin-top: 2rem;
  }

  .md\:mt-3 {
    margin-top: 3rem;
  }

  .md\:mt-4 {
    margin-top: 4rem;
  }

  .md\:mt-5 {
    margin-top: 5rem;
  }

  .md\:mt-6 {
    margin-top: 6rem;
  }

  .md\:mt-10 {
    margin-top: 10rem;
  }
  .md\:mt-10-5 {
    margin-top: 10.5rem;
  }
  .md\:mt-10-7 {
    margin-top: 10.7rem;
  }
  .md\:mb-0-5 {
    margin-bottom: 0.5rem;
  }
  .md\:mb-0-6 {
    margin-bottom: 0.6rem;
  }
  .md\:mb-0-7 {
    margin-bottom: 0.7rem;
  }
  .md\:mb-0-8 {
    margin-bottom: 0.8rem;
  }
  .md\:mb-1-25 {
    margin-bottom: 1.25rem;
  }

  .md\:mb-1-7 {
    margin-bottom: 1.7rem;
  }

  .md\:mb-2-1 {
    margin-bottom: 2.1rem;
  }

  .md\:mb-3 {
    margin-bottom: 3rem;
  }

  .md\:mb-3-125 {
    margin-bottom: 3.125rem;
  }

  .md\:mb-3-5 {
    margin-bottom: 3.5rem;
  }

  .md\:mb-5-5 {
    margin-bottom: 5.5rem;
  }

  .md\:mb-6 {
    margin-bottom: 6rem;
  }

  .md\:mb-17-5 {
    margin-bottom: 17.5rem;
  }
  .md\:mlr-10 {
    margin-right: 10rem;
    margin-left: 10rem;
  }
  .md\:mlr-6 {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .md\:h-5 {
    height: 5rem;
  }
  .md\:w-3-5 {
    width: 3.5rem;
  }

  .md\:w-21-25 {
    width: 21.25rem;
  }

  .md\:w-41 {
    width: 41rem;
  }

  .md\:mw-16-25 {
    max-width: 16.25rem;
  }
  .md\:mw-21-25 {
    max-width: 21.25rem;
  }
  .md\:mw-28 {
    max-width: 28rem;
  }
  .md\:mw-60 {
    max-width: 60rem;
  }
  .md\:mh-65-5 {
    max-height: 65.5rem;
  }

  .md\:p-2 {
    padding: 2rem;
  }

  .md\:plr-3 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .md\:pt-3-3 {
    padding-top: 3.3rem;
  }

  .md\:plr-4-75 {
    padding-left: 4.75rem;
    padding-right: 4.75rem;
  }

  .md\:plr-8 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:cg-9 {
    column-gap: 9rem;
  }
  .md\:rg-4-4 {
    row-gap: 4.4rem;
  }
}
